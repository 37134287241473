<template>
    <div>
        <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" class="mb-5">
                <a-select style="width: 200px; margin-right: 10px" placeholder="Select APIKEY" :loading="is_apikey_loading" @change="handleSelectApikeyChange">
                    <a-select-option v-for="v in api_key_list" :key="v.id" :value="v.id">
                        {{v.name}}
                    </a-select-option>
                </a-select>
                <a-select 
                style="width: 200px; margin-right: 10px"
                placeholder="Select PAIR" 
                showSearch
                :loading="is_pair_loading"
                @change="handleSelectPairChange"
                >
                    <a-select-option v-for="(v, i) in symbol_list" :key="i" :value="v">
                        {{v}}
                    </a-select-option>
                </a-select>
                <a-input
                style="width: 200px; margin-right: 10px"
                placeholder="Binding Server" 
                :value="selectedApiKeyServer" 
                disabled>
                </a-input>
            </a-col>
            <!-- <template v-if="select_api_key_id && select_pair && select_coin">
                <a-divider />                 
                <balance ref="balance_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server"/>
            </template> -->
            <template v-if="select_api_key_id && select_pair && select_coin">
                <div style="display: flex; justify-content: space-between;">
                    <div style="flex: 1; margin-right: 10px;">
                        <a-divider />                 
                        <balance ref="balance_ref" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server"/>
                    </div>
                    <div style="flex: 1; margin-left: 10px; display: flex; flex-direction: column;">
                        <a-divider />
                        <div style="display: flex;">
                            <a-button class='mb-5 mr-5' button-style="solid" @click="toggleBalanceRef2">{{ showBalanceRef2 ? 'All Balance(Show)' : 'All Balance(Hide)' }}</a-button>
                            <div v-show="showBalanceRef2">
                                <balances ref="balance_ref2" :coin="select_coin" :pair="select_pair" :apikeyId="select_api_key_id" :running_server="select_server"/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <a-divider />
            
        </a-row>
        <!--호가창-->
        <template v-if="select_api_key_id && select_pair && select_coin">
            <a-row :gutter="24" type="flex" align="stretch">
                <a-col :span="14">
                    <a-input-number v-model="click_order_amount" placeholder="Click Order(Amount)" style="width: 300px;" class='mb-5 mr-5'></a-input-number>
                    <a-radio-group v-model="select_click_order_type" class='mb-5 mr-5' button-style="solid">
                        <a-radio-button value="bid">Buy</a-radio-button>
                        <a-radio-button value="ask" class='danger'>Sell</a-radio-button>
                    </a-radio-group>
                </a-col>
            </a-row>
            <a-row type="flex" justify="start">
                <!--주문 입력 창-->
                
                <a-col class="mb-24" :xl="{ order: 0 }" :xxl="{ order: 1 }" flex="0 0 400px">
                    <a-card style="margin-bottom: 20px;">
                        <div style="margin-bottom: 10px;"><strong> Lmit Order </strong></div>

                        <a-input-number v-model.number="input_price" placeholder="price" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        
                        <a-input-number v-model.number="input_amount" placeholder="amount" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        <a-row type="flex" :gutter="6" class="card-footer mt-5" align="middle">
                            <a-col :span="12">
                                <a-button type="primary" size="small" :loading="false" @click="handleAskButton">Buy</a-button>
                            </a-col>
                            <a-col :span="12" class="text-right">
                                <a-button type="danger" size="small" :loading="false" @click="handleBidButton">Sell</a-button>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card style="margin-bottom: 20px;">
                        <div style="margin-bottom: 10px;"><strong> Market Order(Buy) </strong></div>

                        <a-input-number v-model.number="market_buy_amount_min" :placeholder="`주문 총액(${select_pair.toUpperCase()}) Min`" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        
                        <a-input-number v-model.number="market_buy_amount_max" :placeholder="`주문 총액(${select_pair.toUpperCase()}) Max`" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>

                        <a-row type="flex" :gutter="6" class="card-footer mt-5" align="middle">
                            <a-col :span="12">
                                <a-button type="primary" size="small" :loading="false" @click="handleMarketBuyButton">Buy</a-button>
                            </a-col>
                        </a-row>
                    </a-card>

                    <a-card>
                        <div style="margin-bottom: 10px;"><strong> Market Order(Sell) </strong></div>

                        <a-input-number v-model.number="market_sell_amount_min" :placeholder="`주문 수량(${select_coin.toUpperCase()}) Min`" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        
                        <a-input-number v-model.number="market_sell_amount_max" :placeholder="`주문 수량(${select_coin.toUpperCase()}) Max`" style="width: 100%">
                            <!--<a-icon slot="prefix" type="user" />-->
                        </a-input-number>
                        
                        <a-row type="flex" :gutter="6" class="card-footer mt-5" align="middle">
                            <a-col :span="12">
                                <a-button type="danger" size="small" :loading="false" @click="handleMarketSellButton">Sell</a-button>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>

                <a-col class="mb-24" :xl="{ order: 1 }" :xxl="{order: 0 }" flex="1 1 1200px">
                    <ordertable :pair="select_pair"
                                :coin="select_coin"
                                :selectClickOrderType="select_click_order_type"
                                :selectPriceUnit="select_price_unit"
                                :selectCumViewType="select_click_cum_view_type"
                                :running_server="select_server"
                                @setQuantityForm="set_quantity_form"
                                @setPriceForm="set_price_form"
                                @clickOrderAsk="handleClickOrderAsk"
                                @clickOrderBid="handleClickOrderBid"
                    />
                </a-col>
            
            </a-row>
            <a-row :gutter="24" type="flex" align="stretch">
                <!--주문현황-->
                <a-col :sm="24" :xl="20" :xxl="10">
                    <openOrders ref="open_orders_ref" :pair="select_pair" :coin="select_coin" :apikeyId="select_api_key_id" :running_server="select_server"/>
                </a-col>
                <!--오더리스트-->
                <a-col :sm="24" :xl="20" :xxl="10">
                    <div style="margin-top: 10px; margin-bottom: 10px;"><strong>* 현재 시간으로부터 24시간(24 hours) 이내 주문만 조회가능</strong></div>
                    <orders ref="orders_ref" :pair="select_pair" :coin="select_coin" :apikeyId="select_api_key_id" :running_server="select_server"/>
                </a-col>
            </a-row>
        </template>
        
    </div>
</template>
<script>

import ordertable from './ordertable';
import orders from './orders';
import openOrders from './openOrders';
import balance from './balance';
import balances from './balance_all';

const ExchangeName = 'lbank';

export default {
    components: {
        ordertable,
        orders,
        openOrders,
        balance,
        balances,
    },
    data() {
        return {
            input_price: '',
            input_amount: '',
            is_apikey_loading: true,
            is_pair_loading: false,
            select_api_key_id: null,
            api_key_list: [],
            symbol_list: [],
            select_coin: null,
            select_pair: null,
            click_order_amount: '',
            select_click_order_type: 'ask',
            price_unit: [
                1, 10, 100, 1000
            ],
            select_price_unit: 1,
            select_click_cum_view_type: 'amount',
            selectedApiKeyServer: '',
            select_server: null,
            market_buy_amount_min: '',
            market_buy_amount_max: '',
            market_sell_amount_min: '',
            market_sell_amount_max: '',
            calced_precision: '',
            showBalanceRef2: false,
        }
    },
    methods: {
        toggleBalanceRef2() {
            this.showBalanceRef2 = !this.showBalanceRef2;
        },
        handleSelectPriceUnitChange(value){
            this.select_price_unit = value
        },
        handleSelectApikeyChange(value){
            this.select_api_key_id = value
            this.is_pair_loading = true
            const _symbols = this.api_key_list.filter((v) => v.id === value)
            if (_symbols.length > 0) {
                this.symbol_list = _symbols[0].currencies;
                this.selectedApiKeyServer = _symbols[0].running_server;
                this.select_server = _symbols[0].running_server;
            } else {
                this.symbol_list = [];
                this.selectedApiKeyServer = '';
            }
            this.is_pair_loading = false
        },
        async handleSelectPairChange(value){
            const v = value.split('/')
            this.select_coin = v[0]
            this.select_pair = v[1]
            this.select_price_unit = 1
            // precision
            const symbol_ob = `${this.select_coin}_${this.select_pair}`;
            const res = await this.$http.get(`/api/v1/exchange2/${ExchangeName}/orderbook/${this.select_server}/${symbol_ob}`);
            let res_data = res.data;
            this.calced_precision = this.calcOrderbook(res_data);

        },
        calcOrderbook(orderbook) {
            let amount_list = [];
            let amount_round_list = [];
            let amount, amount_round, orderbook_amount, amount_str, zero_value, orderbook_index;

            orderbook_index = 20;

            for (let i = 0; i < orderbook_index; i++) {
                orderbook_amount = parseFloat(orderbook.asks[i][1]);

                // 소수점 찾는 정규식
                const decimalRegex = /\.(\d*?)0*$/;

                // 정수 제외 소수점 이하 부분 추출
                const decimalPart = orderbook_amount.toString().match(decimalRegex);

                if (decimalPart) {
                amount_round = decimalPart[1].length;
                amount = 10 ** (-1 * amount_round);
                } else {
                amount_str = orderbook_amount.toString();
                zero_value = 0;
                for (let i = amount_str.length - 1; i >= 0; i--) {
                    if (amount_str[i] !== '0') {
                    zero_value = amount_str.length - 1 - i;
                    break;
                    }
                }
                amount_round = - zero_value
                amount = 10 ** (-1 * amount_round);
                }

                if (!isNaN(amount)) {
                    amount_list.push(amount);
                    amount_round_list.push(amount_round);
                }
            }
            amount = Math.min(...amount_list);
            amount_round = Math.max(...amount_round_list);
            let roundedNumber = Math.round(amount * (10 ** amount_round)) / (10 ** amount_round);

            return amount_round;
        },
        set_price_form(v){
            this.input_price = Number(v)
        },
        set_quantity_form(v){
            this.input_amount = Number(v)
        },
        get_symbol(){
            return `${this.select_coin}_${this.select_pair}`
        },
        getRandomFloat(min, max) {
            return Math.random() * (max - min) + min;
        },
        async get_apikeys(){
            const res = await this.$http.get('/api/v1/apikeys/')
            this.is_apikey_loading = false
            return res.data
        },
        async get_symbols(){
            const res = await this.$http.get(`/api/v1/exchange/${ExchangeName}/symbols`)
            this.is_pair_loading = false
            return res.data
        },
        async handleBidButton(){
            if (this.input_price > 0 || this.input_amount > 0){
                await this.createOrder(this.input_price, this.input_amount, 'sell')
            }
        },
        async handleAskButton(){
            if (this.input_price > 0 || this.input_amount > 0){
                await this.createOrder(this.input_price, this.input_amount, 'buy')
            }
        },
        async handleClickOrderBid(p){
            if (this.click_order_amount > 0 && p > 0){
                await this.createOrder(p, this.click_order_amount, 'buy')
            }
        },
        async handleClickOrderAsk(p){
            if (this.click_order_amount > 0 && p > 0){
                await this.createOrder(p, this.click_order_amount, 'sell')
            }
        },
        async handleMarketBuyButton(){
            // 0. 입력값 확인
            if (this.market_buy_amount_min < 0 || this.market_buy_amount_max < 0) {
                this.$notification.open({
                    message: `[Market Buy] Min, Max 값을 확인하세요`,
                    description: `음수가 입력되었습니다. Min(${this.market_buy_amount_min}), Max(${this.market_buy_amount_max})`,
                    placement: 'bottomLeft',
                });
                return
            }

            // 1. 범위 확인
            // min <= max 맞으면 그대로 안맞으면 notice
            if (this.market_buy_amount_min > this.market_buy_amount_max) {
                this.$notification.open({
                    message: `[Market Buy] Min, Max 값을 확인하세요`,
                    description: `Min(${this.market_buy_amount_min}) > Max(${this.market_buy_amount_max})`,
                    placement: 'bottomLeft',
                });
                return
            } else {
                // 2. 랜덤값 뽑기
                // min <= 랜덤 총액 <= max
                const randomAmount = this.getRandomFloat(this.market_buy_amount_min, this.market_buy_amount_max);

                // precision buy는 노필요
                // const round_amount = this.roundPrice(parseFloat(randomAmount), this.calced_precision);        
                // console.log(round_amount);
                // 최종 수량

                // 3. 시장가 주문 buy unit 0, sell price 0
                await this.marketOrder(randomAmount, 0, 'buy_market');
            }
        },
        async handleMarketSellButton(){
            // 0. 입력값 확인
            if (this.market_sell_amount_min < 0 || this.market_sell_amount_max < 0) {
                this.$notification.open({
                    message: `[Market Sell] Min, Max 값을 확인하세요`,
                    description: `음수가 입력되었습니다. Min(${this.market_sell_amount_min}), Max(${this.market_sell_amount_max})`,
                    placement: 'bottomLeft',
                });
                return
            }

            // 1. 범위 확인
            // min <= max 맞으면 그대로 안맞으면 notice
            if (this.market_sell_amount_min > this.market_sell_amount_max) {
                this.$notification.open({
                    message: `[Market Sell] Min, Max 값을 확인하세요`,
                    description: `Min(${this.market_sell_amount_min}) > Max(${this.market_sell_amount_max})`,
                    placement: 'bottomLeft',
                });
                return
            } else {
                // 2. 랜덤값 뽑기
                // min <= 랜덤 총액 <= max
                const randomAmount = this.getRandomFloat(this.market_sell_amount_min, this.market_sell_amount_max);

                // precision buy는 노필요
                const round_amount = this.roundPrice(parseFloat(randomAmount), this.calced_precision);        
                // console.log(round_amount);
                // 최종 수량

                // 3. 시장가 주문 buy unit 0, sell price 0
                await this.marketOrder(0, round_amount, 'sell_market');
            }
        },
        async marketOrder(p, q, side){
            const body = {
                apikeyId: this.select_api_key_id,
                symbol: this.get_symbol(),
                side,
                amount: q,
                price: p,
                running_server: this.select_server
            }

            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/marketorder`, body)
            if (res.data.statusCode === 200) {
                if (side === 'buy_market') {
                    this.$notification.open({
                            message: `Create Order - Success`,
                            description: `${this.select_pair} - 주문총액: ${p}`,
                            placement: 'bottomLeft',
                    });
                } else {
                    this.$notification.open({
                            message: `Create Order - Success`,
                            description: `${this.select_coin} - 주문수량: ${q}`,
                            placement: 'bottomLeft',
                    });
                }
            } else {
                this.$notification.open({
                        message: `Create Order - Fail..`,
                        description: res.data.errorMessage,
                        placement: 'bottomLeft',
                });
            }
            this.refresh_order_tables()
        },
        roundPrice(value, decimals) {
            if (decimals < 0) {
                const multiplier = 10 ** (- decimals);
                return Math.round(value / multiplier) * multiplier;
            } else {
                return Number(parseFloat(value).toFixed(decimals));
            }
        },
        async createOrder(p, q, side){
            // this.select_api_key_id
            // price
            // quentity
            // exchange name
            const body = {
                apikeyId: this.select_api_key_id,
                symbol: this.get_symbol(),
                side,
                amount: q,
                price: p,
                running_server: this.select_server
            }
            // this.$notification.open({
			// 		message: `Create Order - ${this.get_symbol()}`,
			// 		description: `${q}, ${p}, ${side}`,
			// 		placement: 'topRight',
			// 	});
            const res = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/createorder`, body)
            if (res.data.statusCode === 200) {
                this.$notification.open({
                        message: `Create Order - Success`,
                        description: `${this.select_pair} - Price: ${p} Vol: ${q}`,
                        placement: 'bottomLeft',
                });
            } else {
                this.$notification.open({
                        message: `Create Order - Fail..`,
                        description: res.data.errorMessage,
                        placement: 'bottomLeft',
                });
            }
            this.refresh_order_tables()
        },
        refresh_order_tables(){
            this.$refs.open_orders_ref.UpdateOrders()
            this.$refs.orders_ref.UpdateOrders()
            this.$refs.balance_ref.UpdateBalance()
            this.$refs.balance_ref2.UpdateBalance2()
        },
        async init(){
            const apikey_list = await this.get_apikeys()
            apikey_list.forEach(data => {
                if(data.exchange === ExchangeName){
                    this.api_key_list.push(data)
                }
            })
            // const symbols = await this.get_symbols()
            // this.symbol_list = []
        }
    },
    async mounted() {
        this.init()
    },
    
}
</script>
<style lang="">
    
</style>
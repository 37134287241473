<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-divider/>
            <div style="font-weight: bold; margin-bottom: 8px;">
                기준 거래소
            </div>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>

            <template v-if="configData.exchange">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                        @change="handleApikeyChange"
                    >
                        <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v3.lp_server')" prop="name">
                    <a-input
                        v-model="select_server"
                        size="small"
                        style="width: 40%"
                        disabled
                        placeholder="Binding Server"
                    />
                </a-form-model-item> 

                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v3.target_price')" prop="name">
                    <a-input-number
                            v-model="base_price"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                </a-form-model-item>

                <a-divider/>
                <div style="font-weight: bold; margin-bottom: 8px;">
                    페어 거래소
                </div>

                <a-form-model-item label="Exchange" prop="name">
                    <a-select
                        v-model="configData.exchange2"
                        size="small"
                        style="width: 40%"
                        @change="changeExchangeSelect2"
                    >
                        <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey2"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading2"
                        @change="handleApikeyChange2"
                    >
                        <a-select-option v-for="v in apikey_list3" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v3.lp_server')" prop="name">
                    <a-input
                        v-model="select_server2"
                        size="small"
                        style="width: 40%"
                        disabled
                        placeholder="Binding Server"
                    />
                </a-form-model-item> 

                <!-- <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin2"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading2"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list2" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item> -->

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair2"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading2"
                        showSearch
                    >
                        <a-select-option v-for="v in pair_list2" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item v-if="this.base_price === -1" :label="$t('fillblank_v3.target_price')" prop="name">
                    <a-input-number
                            v-model="base_price2"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                </a-form-model-item>
            </template>
        
        </a-form-model>
        <a-divider/>
        <template v-if="configData.exchange2 && configData.pair2">
            <!-- config -->
            <a-form-model
                :model="configDetailData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">

                <template v-for="form in config_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        <template v-if="form.type === 'number'">
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :min="0"
                                :max="100"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                v-if="form.filter === 'percent'"
                            />
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                v-else
                            />
                        </template>
                        <template v-if="form.type === 'boolean'">
                            <a-switch
                                v-model="configDetailData[form.value]"
                            />
                        </template>
                    </a-form-model-item>
                </template>

                <a-divider/>
                <div style="margin-top: 10px;">* 강제체결 관련(비활성화:-1) *</div>
                <a-form-model-item :label="$t('fillblank.is_opposite_allowance')" prop="name">
                    <a-switch
                        v-model="is_opposite_allowance"
                    ></a-switch>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank.opposite_upper_limit')" prop="name">
                    <a-input-number
                        v-model="opposite_upper_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank.opposite_lower_limit')" prop="name">
                    <a-input-number
                        v-model="opposite_lower_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank.order_sell_amount_out_limit')" prop="name">
                    <a-input-number
                        v-model="order_sell_amount_out_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank.order_buy_amount_out_limit')" prop="name">
                    <a-input-number
                        v-model="order_buy_amount_out_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>

                <a-divider/>
                <a-form-model-item :label="$t('fillblank.telegram1')" prop="name">
                    <a-select
                        v-model="select_telegram_id"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank.telegram2')" prop="name">
                    <a-select
                        v-model="select_telegram_id2"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="Select On/Off" prop="name">
                    <a-switch
                        v-model="telegram_select_active"
                    ></a-switch>
                </a-form-model-item>

            </a-form-model>
            <a-form-model
                :model="configOptionPrecisionData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">

                <template v-for="form in option_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        
                        <a-input-number
                            v-model="configOptionPrecisionData[form.value]"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    
                        />
                    </a-form-model-item>
                </template>
            </a-form-model>
            
            <a-row>
                <a-col>
                    <span>{{ error_log }}</span>
                </a-col>
                <a-col>
                    <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
                </a-col>
            </a-row>
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            configData: {},
            configDetailData: {
                is_ask_mode: false,
                is_bid_mode: false,
                is_out_amount_ignore: false
            },
            configOptionPrecisionData: {
                
            },
            rules: {},
            exchange_list:
            [
                
            ],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            pair_list2:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            option_form: [
                // {
                //     label: '소수점지정(수량)',
                //     value: 'amount',
                //     type: 'number',
                //     required: false
                // },
                {
                    label: this.$i18n.t("fillblank_v3.precision"),
                    value: 'price',
                    type: 'number',
                    required: false
                },
            ],
            config_form: [
                {
                    label: this.$i18n.t("fillblank.speed"),
                    value: 'speed',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.amount_min"),
                    value: 'amount_min',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.amount_max"),
                    value: 'amount_max',
                    type: 'number',
                    required: true
                },
                // {
                //     label: this.$i18n.t("fillblank.target_price"),
                //     value: 'target_price',
                //     type: 'number',
                //     required: true
                // },
                {
                    label: this.$i18n.t("fillblank.fill_range_percent"),
                    value: 'fill_range_percent',
                    type: 'number',
                    filter: 'percent',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.fill_total_price"),
                    value: 'fill_total_price',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.target_fill_gap"),
                    value: 'target_fill_gap',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.is_ask_mode"),
                    value: 'is_ask_mode',
                    type: 'boolean',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.is_bid_mode"),
                    value: 'is_bid_mode',
                    type: 'boolean',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.is_out_amount_ignore"),
                    value: 'is_out_amount_ignore',
                    type: 'boolean',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.is_cancel_out_range"),
                    value: 'is_cancel_out_range',
                    type: 'boolean',
                    required: true
                },
                // {
                //     label: this.$i18n.t("fillblank.is_opposite_allowance"),
                //     value: 'is_opposite_allowance',
                //     type: 'boolean',
                //     required: true
                // },
                {
                    label: this.$i18n.t("fillblank.coin_protect"),
                    value: 'coin_protect',
                    type: 'number',
                    required: false
                },
                {
                    label: this.$i18n.t("fillblank.pair_protect"),
                    value: 'pair_protect',
                    type: 'number',
                    required: false
                },
            ],
            telegram_list: [],
            select_telegram_id: null,
            select_telegram_id2: null,
            symbols_list: [],
            symbols_list2: [],
            apikey_list: [],
            apikey_list2: [],
            is_apikey_loading: true,
            is_symbols_loading: true,
            is_apikey_loading2: true,
            is_symbols_loading2: true,
            error_log: '',
            is_edit: false,
            apikey_list3: [],
            apikey_list4: [],
            select_server: null,
            telegram_select_active: false,
            base_price: 0,
            base_price2: 0,
            apikey_name2: '',
            is_opposite_allowance: false,
            opposite_upper_limit: -1,
            opposite_lower_limit: -1,
            order_buy_amount_out_limit: -1,
            order_sell_amount_out_limit: -1,
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        // get apikeys
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            // await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.select_server = selectedApiKey.running_server;
                await this.get_symbols(this.configData.exchange, this.select_server);
            }
        },
        async get_symbols(exchange, runserver){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async changeExchangeSelect2(v){
            
            await this.get_apikeys2(v)
            // await this.get_symbols(v)
        },
        async get_apikeys2(v){
            this.apikey_list3 = []
            this.is_apikey_loading2 = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list3.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list4 = res.data
            this.is_apikey_loading2 = false
        },
        async handleApikeyChange2(value) {
            const selectedApiKey2 = this.apikey_list4.find(apikey => apikey.id === value);
            if (selectedApiKey2) {
                this.apikey_name2 = selectedApiKey2.name;
                this.select_server2 = selectedApiKey2.running_server;
                await this.get_symbols2(this.configData.exchange2, this.select_server2);
            }
        },
        async get_symbols2(exchange, runserver){
            this.symbols_list2 = []
            this.is_symbols_loading2 = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list2 = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list2.find(s => s.label === _symbol[0])) {
                        this.symbols_list2.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list2.find(s => s.label === _symbol[1])) {
                        this.pair_list2.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list2.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            
            if (!this.symbols_list2.find(s => s.value === this.configData.coin.toLowerCase())) {
                this.pair_list2 = [];
                this.$notification.open({
                        message: `Bad Symbol`,
                        description: `기준 거래소의 토큰을 페어 거래소에서 불러올 수 없습니다.`,
                        placement: 'bottomLeft',
                    });
            }

            this.is_symbols_loading2 = false
            return res.data
        },
        async emitBtn(){
            await this.registCrossTrade()
        },
        async registCrossTrade(){
            const crossTrade = {
                ...this.configData,
                server: this.select_server,
                server2: this.select_server2,
                apikeyname2: this.apikey_name2,
                telegram_id: this.select_telegram_id,
                telegram_id2: this.select_telegram_id2,
                telegram_select: this.telegram_select_active,
                is_opposite_allowance_: this.is_opposite_allowance,
                opposite_upper_limit_: this.opposite_upper_limit,
                opposite_lower_limit_: this.opposite_lower_limit,
                order_buy_amount_out_limit_: this.order_buy_amount_out_limit,
                order_sell_amount_out_limit_: this.order_sell_amount_out_limit,
                p_price: this.base_price,
                p_price2: this.base_price2,
                config: this.configDetailData,
                options: {
                    precision: this.configOptionPrecisionData
                }
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/fillblank_v3/${this.configData._id}`, crossTrade)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/fillblank_v3/', crossTrade)
                    this.error_log = ''
                }
                this.$router.go();
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_server = record.server
            this.select_server2 = record.server2
            this.apikey_name2 = record.apikeyname2
            this.configOptionPrecisionData = {
                price: record.options?.precision?.price,
                amount: record.options?.precision?.amount
            }
            this.select_telegram_id = record.telegram_id
            this.select_telegram_id2 = record.telegram_id2
            this.telegram_select_active = record.telegram_select
            this.base_price = record.p_price
            this.base_price2 = record.p_price2
            this.is_opposite_allowance = record.is_opposite_allowance_
            this.opposite_upper_limit = record.opposite_upper_limit_
            this.opposite_lower_limit = record.opposite_lower_limit_
            this.order_buy_amount_out_limit = record.order_buy_amount_out_limit_
            this.order_sell_amount_out_limit = record.order_sell_amount_out_limit_
            this.changeExchangeSelect(record.exchange)
            this.changeExchangeSelect2(record.exchange2)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
            this.configOptionPrecisionData = {}
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}
    
</style>
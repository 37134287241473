<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <template v-if="configData.exchange">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                        @change="handleApikeyChange"
                    >
                        <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </template>
        
        </a-form-model>
        <a-divider/>
        <template v-if="configData.exchange && configData.coin && configData.pair">
            <!-- config -->
            <a-form-model
                :model="configDetailData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">

                <template v-for="form in config_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        <template v-if="form.type === 'number'">
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :min="0"
                                :max="100"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                v-if="form.filter === 'percent'"
                            />
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                v-else
                            />
                        </template>
                        <template v-if="form.type === 'boolean'">
                            <a-switch
                                v-model="configDetailData[form.value]"
                            />
                        </template>
                    </a-form-model-item>
                </template>
                <a-divider/>
                <a-form-model-item :label="$t('cross_trade_v2.gap_method')" prop="name">
                    <a-select
                        v-model="gap_method"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in gap_method_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="value" prop="name">
                    <a-input-number
                            v-model="gap_method_value"
                            size="small"
                            style="width: 40%"
                            :formatter="value => {
                                const parts = value.toString().split('.');
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                return parts.join('.');
                            }"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            :min="0"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('cross_trade_v2.base_price')" prop="name">
                    <a-select
                        v-model="base_price"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in base_price_options" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :label="$t('cross_trade_v2.after_price')" prop="name">
                    <a-select
                        v-model="after_price"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in base_price_options2" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-divider/>
                <template>
                    <div>
                        <a-form-model-item :label="$t('cross_trade_v2.amount_min')" prop="name">
                            <a-input 
                                v-model="newItem1" 
                                size="small"
                                style="width: 40%"
                                @input="handleInput"
                                placeholder="Amount Min">
                            </a-input>
                        </a-form-model-item>

                        <a-form-model-item :label="$t('cross_trade_v2.amount_max')" prop="name">
                            <a-input 
                                v-model="newItem2" 
                                size="small"
                                style="width: 40%"
                                @input="handleInput2"
                                placeholder="Amount Max">
                            </a-input>
                        </a-form-model-item>

                        <a-button type="primary" shape="circle" size="small" style="margin-left: 40px;" @click="addItem" :disabled="!areBothItemsEntered">+</a-button>
                        <div v-for="(pairIndex, index) in Math.floor(items.length / 2)" :key="pairIndex">
                            <div class="item-content">
                                <div style="margin-left: 40px; margin-top: 10px;">Amount {{index+1}} : {{ items[index * 2] }} ~ {{ items[index * 2 + 1] }}</div>
                                <a-button type="primary" shape="circle" size="small" @click="deleteItem(index)">-</a-button>
                            </div>
                        </div>
                    </div>
                </template>
                
                <a-divider/>
                <a-form-model-item :label="$t('cross_trade_v2.telegram1')" prop="name">
                    <a-select
                        v-model="select_telegram_id"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :label="$t('cross_trade_v2.telegram2')" prop="name">
                    <a-select
                        v-model="select_telegram_id2"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="Select On/Off" prop="name">
                    <a-switch
                        v-model="telegram_select_active"
                    ></a-switch>
                </a-form-model-item>

            </a-form-model>
            <a-row>
                <a-col>
                    <span>{{ error_log }}</span>
                </a-col>
                <a-col>
                    <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
                </a-col>
            </a-row>
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            configData: {},
            configDetailData: {},
            rules: {},
            exchange_list:
            [
                
            ],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            gap_method_options: [
                {
                    label: 'Tick',
                    value: 'tick',
                },
                {
                    label: 'Percent',
                    value: 'percent',
                },
            ],
            base_price_options: [
                {
                    label: 'Ask',
                    value: 'ask',
                },
                {
                    label: 'Bid',
                    value: 'bid',
                },
                {
                    label: 'Last',
                    value: 'last',
                },
            ],
            base_price_options2: [
                {
                    label: 'Ask',
                    value: 'ask',
                },
                {
                    label: 'Bid',
                    value: 'bid',
                },
                {
                    label: 'Last',
                    value: 'last',
                },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            config_form: [
                {
                    label: this.$i18n.t("cross_trade_v2.speed_min"),
                    value: 'speed_min',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.speed_max"),
                    value: 'speed_max',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.trend"),
                    value: 'trend',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.cross_balance"),
                    value: 'cross_balance',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.gap_amount"),
                    value: 'gap_amount',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.order_buy_amount_out_limit"),
                    value: 'order_buy_amount_out_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.order_sell_amount_out_limit"),
                    value: 'order_sell_amount_out_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("cross_trade_v2.coin_protect"),
                    value: 'coin_protect',
                    type: 'number',
                    required: false
                },
                {
                    label: this.$i18n.t("cross_trade_v2.pair_protect"),
                    value: 'pair_protect',
                    type: 'number',
                    required: false
                },
            ],
            telegram_list: [],
            symbols_list: [],
            apikey_list: [],
            select_telegram_id: null,
            select_telegram_id2: null,
            is_apikey_loading: true,
            is_symbols_loading: true,
            error_log: '',
            is_edit: false,
            gap_method: null,
            gap_method_value: 0,
            base_price: null,
            newItem1: '',
            newItem2: '', 
            items: [],
            apikey_list2: [],
            select_server: null,
            after_price: null,
            telegram_select_active: false
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        // get apikeys
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            // await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id,
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.select_server = selectedApiKey.running_server;
                await this.get_symbols(this.configData.exchange, this.select_server);
            }
        },
        async get_symbols(exchange, runserver){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async emitBtn(){
            await this.registCrossTrade()
        },
        async registCrossTrade(){
            const crossTrade_v2 = {
                ...this.configData,
                server: this.select_server,
                telegram_id: this.select_telegram_id,
                telegram_id2: this.select_telegram_id2,
                telegram_select: this.telegram_select_active,
                config: {...this.configDetailData, gap: {
                    method_type: this.gap_method,
                    range: this.gap_method_value,
                    start_price: this.base_price,
                    after_price_: this.after_price,
                    amounts: this.items,
                }}
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/crosstrade_v2/${this.configData._id}`, crossTrade_v2)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/crosstrade_v2/', crossTrade_v2)
                    this.error_log = ''
                }
                // 원본
                this.$router.go();
                // const currentRoute = this.$router.currentRoute;
                // this.$router.push(currentRoute).catch(() => {});
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        handleInput(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem1 = event.target.value;
        },
        handleInput2(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem2 = event.target.value;
        },
        areBothItemsEntered() {
            // Check if both input fields have value
            return this.newItem1.trim() !== '' && this.newItem2.trim() !== ''
        },
        addItem() {
            if (this.areBothItemsEntered()) {
                this.items.push(this.newItem1, this.newItem2);
                this.newItem1 = ''
                this.newItem2 = ''
            }
        },
        deleteItem(index) {
            const startIndex = index * 2;
            this.items.splice(startIndex, 2);
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_server = record.server
            this.select_telegram_id = record.telegram_id
            this.select_telegram_id2 = record.telegram_id2
            this.telegram_select_active = record.telegram_select
            this.gap_method = record.config.gap.method_type 
            this.gap_method_value = record.config.gap.range 
            this.base_price = record.config.gap.start_price 
            this.after_price = record.config.gap.after_price_
            this.items = record.config.gap.amounts
            this.changeExchangeSelect(record.exchange)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
            this.amounts = []
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}

.item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
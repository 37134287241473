<template>
    <div>
        <div style="display: flex; align-items: center;">
            <a-button @click="UpdateOrders()" class="ml-5">
                <a-icon type="reload" theme="outlined" />
                    Refresh
            </a-button>
            <div v-if="exchange_name === 'bithumb'" style="margin-left: 10px;">
                <strong>* 원화 입금내역은 지원하지 않습니다.</strong>
            </div>
            <div v-if="exchange_name === 'bitget'" style="margin-left: 10px;">
                <strong>* 현재 시간으로부터 일주일(7 days) 이내 입금내역 조회 가능</strong>
            </div>
            <div v-if="exchange_name === 'lbank'" style="margin-left: 10px;">
                <strong>* 현재 시간으로부터 90일(90 days) 이내 입금내역 조회 가능</strong>
            </div>
            <div v-if="exchange_name === 'coinbase'" style="margin-left: 10px;">
                <strong>* Wallet 선택 시 입금내역 조회 가능</strong>
            </div>
        </div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true"
                     :scroll="{ y: 1200 }">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
// const ExchangeName = 'bithumb';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
        network: String,
        exchange_name: String,
        account_id: String
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('asset_movement.trade_date'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => {
                        if (this.exchange_name === 'bithumb') {
                            return a.transfer_date - b.transfer_date
                        } else if (this.exchange_name === 'binance' || this.exchange_name === 'lbank') {
                            return a.insertTime - b.insertTime
                        } else if (this.exchange_name === 'bitget') {
                            return a.cTime - b.cTime
                        } else if (this.exchange_name === 'coinbase') {
                            return Date(a.created_time) - new Date(b.created_time)
                        }
                    },
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('asset_movement.trade_condition'),
                    dataIndex: 'search',
                },
                {
                    title: this.$i18n.t('asset_movement.token_name'),
                    dataIndex: 'order_currency',
                },
                {
                    title: this.$i18n.t('asset_movement.trade_amount'),
                    dataIndex: 'units',
                },
                {
                    title: this.$i18n.t('asset_movement.trade_fee'),
                    dataIndex: 'fee',
                },
                {
                    title: this.$i18n.t('asset_movement.trade_network'),
                    dataIndex: 'network',
                },
            ],
            // bithumb
            //  {
            //     "search": "5",
            //     "transfer_date": "1722405728211627",
            //     "order_currency": "USDT",
            //     "payment_currency": "KRW",
            //     "units": "- 10.0",
            //     "price": "0",
            //     "amount": "0",
            //     "fee_currency": "USDT",
            //     "fee": "0",
            //     "order_balance": "48.94204336",
            //     "payment_balance": "2"
            //   },
            //binance
            //   {
            //     "id": "4159250630760282113",
            //     "amount": "17.515543",
            //     "coin": "USDT",
            //     "network": "TRX",
            //     "status": 1,
            //     "address": "TMbZupba8yYip3WbmrdABT8vgXZPrhj58M",
            //     "addressTag": "",
            //     "txId": "4412a34f080ca359211a66e9498f51ad6b99d63017f63c1b676f74781a4ad850",
            //     "insertTime": 1725869056000,
            //     "transferType": 0,
            //     "confirmTimes": "1/1",
            //     "unlockConfirm": 0,
            //     "walletType": 0
            //   },
            //bitget
            //   {
            //     "orderId": "1215466061865033728",
            //     "tradeId": "0563bf3b4baa73cd88586fb7abaefc6e7c87fe59c1f492111fdfb3d27fdb0e1a",
            //     "coin": "USDT",
            //     "type": "deposit",
            //     "size": "26.87612900",
            //     "status": "success",
            //     "toAddress": "TGixHJy5avm2q8ggdd7D9zKydYGhqyHDeq",
            //     "dest": "on_chain",
            //     "chain": "TRX(TRC20)",
            //     "fromAddress": null,
            //     "cTime": "1725515612093",
            //     "uTime": "1725515655018"
            //   }
            // lbank
            // {
            //   "insertTime": 1726131085000,
            //   "amount": 14,
            //   "address": "TYASr5UV6HEcXatwdFQfmLVUqQQQMUxHLS",
            //   "networkName": "trc20",
            //   "txId": "a14b8a84bc6b38156d48b84364affafcc5f7a135a949790d6bb7152ea8cdae40",
            //   "coin": "usdt",
            //   "status": "2"
            // },
            // coinbaase
            // {
            //   "amount": {
            //     "amount": "11.200000",
            //     "currency": "USDT"
            //   },
            //   "created_at": "2024-10-07T07:20:27Z",
            //   "id": "80c728f0-9ecd-5435-b5dc-d2bdb6f7ce0e",
            //   "native_amount": {
            //     "amount": "11.20",
            //     "currency": "USD"
            //   },
            //   "network": {
            //     "hash": "fda0e801ec44da8078300451f8993f408bb9a447eb1dd1ecda9b6fb8777442dc",
            //     "network_name": "ethereum",
            //     "status": "confirmed"
            //   },
            //   "resource": "transaction",
            //   "resource_path": "/v2/accounts/1d7f06b6-5947-597d-86df-b40d19680dd4/transactions/80c728f0-9ecd-5435-b5dc-d2bdb6f7ce0e",
            //   "status": "completed",
            //   "type": "send"
            // },
            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async UpdateOrders(){
            let res = '';
            // console.log(this.account_id)

            if (this.exchange_name === 'coinbase') {
                res = await this.$http.post(`/api/v1/exchange2/${this.exchange_name}/depositlist`, {
                    apikeyId: this.apikeyId,
                    running_server: this.running_server,
                    accountId: this.account_id,
                })
            } else {
                res = await this.$http.post(`/api/v1/exchange2/${this.exchange_name}/depositlist`, {
                    apikeyId: this.apikeyId,
                    symbol: this.getSymbol(),
                    running_server: this.running_server
                })
            }
            // console.log(res.data)
            this.orders_table = res.data
            let res_ = res.data;
            
            if (this.exchange_name === 'bithumb') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        const date = new Date(parseInt(item.transfer_date) / 1000); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 네트워크
                        item.network = this.network;

                        // 입금상태
                        if (item.search === '4') {
                            item.search = '입금 완료';
                        } else if (item.search === '9') {
                            item.search = '입금 중';
                        } else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        let number = item.units
                        let number2 = 0
                        let number3 = 0
                        if (number === '0') {
                            number3 = 0;
                        } else {
                            let number1 = number.split(' ')[1];
                            number2 = number1.toString().split('.');
                            number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            number3 = number2.join('.');
                        }
                        item.units = number3;

                    });
                }
            } else if (this.exchange_name === 'binance') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.cTime = parseInt(item.insertTime)
                        const date = new Date(item.cTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.coin;

                        // 입금상태
                        if (item.status === 0) {
                            item.search = 'pending';
                        } else if (item.status === 6) {
                            item.search = 'credited but cannot withdraw';
                        } else if (item.status === 7) {
                            item.search = 'Wrong Deposit';
                        } else if (item.status === 8) {
                            item.search = 'Waiting User confirm';
                        } else if (item.status === 1) {
                            item.search = 'Success';
                        } else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.amount
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 수수료
                        item.fee = 0;
                    });
                }
            } else if (this.exchange_name === 'bitget') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.fTime = parseInt(item.cTime)
                        const date = new Date(item.fTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.coin;

                        // 네트워크
                        item.network = item.chain;

                        // 입금상태
                        item.search = item.status;

                        // 수량 표기 변환
                        const number = parseFloat(item.size);
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 수수료
                        item.fee = 0;
                    });
                }
            } else if (this.exchange_name === 'lbank') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.cTime = parseInt(item.insertTime)
                        const date = new Date(item.cTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.coin.toUpperCase();
                        
                        // 네트워크
                        item.network = item.networkName;

                        // 입금상태
                        if (item.status === "1") {
                            item.search = 'Applying';
                        } else if (item.status === "2") {
                            item.search = 'Successful';
                        } else if (item.status === "3") {
                            item.search = 'Failed';
                        } else if (item.status === "4") {
                            item.search = 'Already Cancel';
                        } else if (item.status === "5") {
                            item.search = 'Transfer';
                        } else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.amount
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 수수료
                        item.fee = 0;
                    });
                }
            } else if (this.exchange_name === 'coinbase') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        const originTime = item.created_at;
                        const date = new Date(originTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.amount.currency.toUpperCase();
                        
                        // 네트워크
                        item.network = item.network.network_name;

                        // 입금상태
                        item.search = item.status;

                        // 수량 
                        item.units = parseFloat(item.amount.amount);
                        item.units = Number(item.units).toLocaleString(undefined, { maximumFractionDigits: 8 });

                        // 수수료
                        item.fee = 0;
                    });
                }
            }
            
            this.orders_table = res_;
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>